import request from './request_service';
class AppService {


    static getGiveaway(id) {
        let options = Object();
        options.url = "https://uauth.unifiedorbit.com/api/v1/general/giveaway/" + id;
        options.params = null;
        options.authenticated = true;
        return request.get(options);
    }


}

export default AppService;